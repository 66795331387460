import React, { useState, useEffect } from 'react'
import './styles/app.css'
import Header from './components/Header'
import Breadcrumbs from './components/Breadcrumbs'
import List from './components/List'
import HashChange from 'react-hashchange'
import axios from 'axios'
import Search from './components/Search'

async function getDirs(dir) {
    const res = await axios.get('https://download.nakama.tv/.api/dir' + dir)
    return res.data
}

function App() {
    const [locations, setLocations] = useState([])
    const [currentLocations, setCurrentLocations] = useState([])
    const [breadCrumbs, setBreadCrumbs] = useState([])
    useEffect(() => {
        const pathname = window.location.pathname
        if (pathname && pathname !== '/') {
            if (!window.location.origin.includes('localhost')) {
                //do nothing when testing
                console.log('legacy path, converting')
                window.location = window.location.origin + '/#' + pathname
            }
        }
        let dir = '/'
        if (window.location.hash) {
            dir = window.location.hash.substring(1)
        }
        getDirs(dir).then((dirs) => {
            setLocations(dirs)
            setCurrentLocations(dirs)
        })
        const path = window.location.hash.substring(2)
        const crumbs = path.indexOf('/') >= 0 ? path.split('/') : path.length > 0 ? [path] : []
        setBreadCrumbs(crumbs)
    }, [])

    return (
        <div className="page">
            <HashChange
                onChange={({ hash }) => {
                    const path = hash.substring(2)
                    const crumbs =
                        path.indexOf('/') >= 0 ? path.split('/') : path.length > 0 ? [path] : []
                    setBreadCrumbs(crumbs)
                    getDirs('/' + path).then((dirs) => {
                        setLocations(dirs)
                        setCurrentLocations(dirs)
                    })
                }}
            />
            <div className="page-inner">
                <Header />
                <Search currentLocations={currentLocations} setLocations={setLocations} />
                <Breadcrumbs directories={breadCrumbs} />
                <List locations={locations} />
            </div>
        </div>
    )
}

export default App

import prettyBytes from 'pretty-bytes'

const extensions = {
    '3dm': { svg: '3dm.svg', name: '' },
    '3ds': { svg: '3ds.svg', name: '' },
    '3g2': { svg: '3g2.svg', name: '' },
    '3gp': { svg: '3gp.svg', name: '' },
    '7z': { svg: '7z.svg', name: '7-Zip Archive' },
    aac: { svg: 'aac.svg', name: '' },
    ai: { svg: 'ai.svg', name: '' },
    aif: { svg: 'aif.svg', name: '' },
    apk: { svg: 'apk.svg', name: '' },
    app: { svg: 'app.svg', name: '' },
    asf: { svg: 'asf.svg', name: '' },
    asp: { svg: 'asp.svg', name: '' },
    aspx: { svg: 'aspx.svg', name: '' },
    asx: { svg: 'asx.svg', name: '' },
    avi: { svg: 'avi.svg', name: '' },
    bak: { svg: 'bak.svg', name: '' },
    bat: { svg: 'bat.svg', name: '' },
    bin: { svg: 'bin.svg', name: '' },
    bmp: { svg: 'bmp.svg', name: '' },
    cab: { svg: 'cab.svg', name: '' },
    cad: { svg: 'cad.svg', name: '' },
    cdr: { svg: 'cdr.svg', name: '' },
    cer: { svg: 'cer.svg', name: '' },
    cfg: { svg: 'cfg.svg', name: '' },
    cfm: { svg: 'cfm.svg', name: '' },
    cgi: { svg: 'cgi.svg', name: '' },
    class: { svg: 'class.svg', name: '' },
    com: { svg: 'com.svg', name: '' },
    cpl: { svg: 'cpl.svg', name: '' },
    cpp: { svg: 'cpp.svg', name: '' },
    crx: { svg: 'crx.svg', name: '' },
    csr: { svg: 'csr.svg', name: '' },
    css: { svg: 'css.svg', name: '' },
    csv: { svg: 'csv.svg', name: '' },
    cue: { svg: 'cue.svg', name: '' },
    cur: { svg: 'cur.svg', name: '' },
    dat: { svg: 'dat.svg', name: '' },
    db: { svg: 'db.svg', name: '' },
    dbf: { svg: 'dbf.svg', name: '' },
    dds: { svg: 'dds.svg', name: '' },
    dem: { svg: 'dem.svg', name: '' },
    dfx: { svg: 'dfx.svg', name: '' },
    dll: { svg: 'dll.svg', name: '' },
    dmg: { svg: 'dmg.svg', name: '' },
    dmp: { svg: 'dmp.svg', name: '' },
    doc: { svg: 'doc.svg', name: '' },
    docx: { svg: 'docx.svg', name: '' },
    drv: { svg: 'drv.svg', name: '' },
    dtd: { svg: 'dtd.svg', name: '' },
    dwg: { svg: 'dwg.svg', name: '' },
    elf: { svg: 'elf.svg', name: '' },
    eps: { svg: 'eps.svg', name: '' },
    exe: { svg: 'exe.svg', name: 'Windows Executable' },
    file: { svg: 'file.svg', name: '' },
    fla: { svg: 'fla.svg', name: '' },
    flash: { svg: 'flash.svg', name: '' },
    flv: { svg: 'flv.svg', name: '' },
    fnt: { svg: 'fnt.svg', name: '' },
    folder: { svg: 'folder.svg', name: '<Directory>' },
    fon: { svg: 'fon.svg', name: '' },
    gam: { svg: 'gam.svg', name: '' },
    gbr: { svg: 'gbr.svg', name: '' },
    ged: { svg: 'ged.svg', name: '' },
    gif: { svg: 'gif.svg', name: '' },
    gpx: { svg: 'gpx.svg', name: '' },
    gz: { svg: 'gz.svg', name: '' },
    gzip: { svg: 'gzip.svg', name: '' },
    hqz: { svg: 'hqz.svg', name: '' },
    html: { svg: 'html.svg', name: '' },
    ibooks: { svg: 'ibooks.svg', name: '' },
    icns: { svg: 'icns.svg', name: '' },
    ico: { svg: 'ico.svg', name: '' },
    ics: { svg: 'ics.svg', name: '' },
    iff: { svg: 'iff.svg', name: '' },
    indd: { svg: 'indd.svg', name: '' },
    iso: { svg: 'iso.svg', name: '' },
    jar: { svg: 'jar.svg', name: '' },
    jpg: { svg: 'jpg.svg', name: '' },
    js: { svg: 'js.svg', name: 'JavaScript File' },
    jsp: { svg: 'jsp.svg', name: '' },
    key: { svg: 'key.svg', name: '' },
    kml: { svg: 'kml.svg', name: '' },
    kmz: { svg: 'kmz.svg', name: '' },
    lnk: { svg: 'lnk.svg', name: '' },
    log: { svg: 'log.svg', name: '' },
    lua: { svg: 'lua.svg', name: 'Lua Script' },
    m3u: { svg: 'm3u.svg', name: '' },
    m4a: { svg: 'm4a.svg', name: '' },
    m4v: { svg: 'm4v.svg', name: '' },
    'mach-o': { svg: 'mach-o.svg', name: '' },
    max: { svg: 'max.svg', name: '' },
    mdb: { svg: 'mdb.svg', name: '' },
    mdf: { svg: 'mdf.svg', name: '' },
    mid: { svg: 'mid.svg', name: '' },
    mim: { svg: 'mim.svg', name: '' },
    mov: { svg: 'mov.svg', name: 'QuickTime File' },
    mp3: { svg: 'mp3.svg', name: 'MP3 Audio File' },
    mp4: { svg: 'mp4.svg', name: 'MPEG-4 Video' },
    mpa: { svg: 'mpa.svg', name: '' },
    mpg: { svg: 'mpg.svg', name: '' },
    msg: { svg: 'msg.svg', name: '' },
    msi: { svg: 'msi.svg', name: 'MSI Installer' },
    nes: { svg: 'nes.svg', name: '' },
    obj: { svg: 'obj.svg', name: '' },
    odb: { svg: 'odb.svg', name: '' },
    odc: { svg: 'odc.svg', name: '' },
    odf: { svg: 'odf.svg', name: '' },
    odg: { svg: 'odg.svg', name: '' },
    odi: { svg: 'odi.svg', name: '' },
    odp: { svg: 'odp.svg', name: '' },
    ods: { svg: 'ods.svg', name: '' },
    odt: { svg: 'odt.svg', name: '' },
    'odt-1': { svg: 'odt-1.svg', name: '' },
    odx: { svg: 'odx.svg', name: '' },
    ogg: { svg: 'ogg.svg', name: '' },
    otf: { svg: 'otf.svg', name: '' },
    'otf-1': { svg: 'otf-1.svg', name: '' },
    pages: { svg: 'pages.svg', name: '' },
    pct: { svg: 'pct.svg', name: '' },
    pdb: { svg: 'pdb.svg', name: '' },
    pdf: { svg: 'pdf.svg', name: '' },
    pif: { svg: 'pif.svg', name: '' },
    pkg: { svg: 'pkg.svg', name: '' },
    pl: { svg: 'pl.svg', name: '' },
    png: { svg: 'png.svg', name: '' },
    pps: { svg: 'pps.svg', name: '' },
    ppt: { svg: 'ppt.svg', name: '' },
    pptx: { svg: 'pptx.svg', name: '' },
    ps: { svg: 'ps.svg', name: '' },
    psd: { svg: 'psd.svg', name: '' },
    pub: { svg: 'pub.svg', name: '' },
    py: { svg: 'py.svg', name: '' },
    ra: { svg: 'ra.svg', name: '' },
    rar: { svg: 'rar.svg', name: '' },
    raw: { svg: 'raw.svg', name: '' },
    rm: { svg: 'rm.svg', name: '' },
    rom: { svg: 'rom.svg', name: '' },
    rpm: { svg: 'rpm.svg', name: '' },
    rss: { svg: 'rss.svg', name: '' },
    rtf: { svg: 'rtf.svg', name: '' },
    sav: { svg: 'sav.svg', name: '' },
    sdf: { svg: 'sdf.svg', name: '' },
    sitx: { svg: 'sitx.svg', name: '' },
    sql: { svg: 'sql.svg', name: '' },
    srt: { svg: 'srt.svg', name: '' },
    svg: { svg: 'svg.svg', name: '' },
    swf: { svg: 'swf.svg', name: '' },
    sys: { svg: 'sys.svg', name: '' },
    tar: { svg: 'tar.svg', name: '' },
    tex: { svg: 'tex.svg', name: '' },
    tga: { svg: 'tga.svg', name: '' },
    thm: { svg: 'thm.svg', name: '' },
    tiff: { svg: 'tiff.svg', name: '' },
    tmp: { svg: 'tmp.svg', name: '' },
    torrent: { svg: 'torrent.svg', name: '' },
    ttf: { svg: 'ttf.svg', name: '' },
    txt: { svg: 'txt.svg', name: 'Text File' },
    uue: { svg: 'uue.svg', name: '' },
    vb: { svg: 'vb.svg', name: '' },
    vcd: { svg: 'vcd.svg', name: '' },
    vcf: { svg: 'vcf.svg', name: '' },
    vob: { svg: 'vob.svg', name: '' },
    wav: { svg: 'wav.svg', name: '' },
    wma: { svg: 'wma.svg', name: '' },
    wmv: { svg: 'wmv.svg', name: '' },
    wpd: { svg: 'wpd.svg', name: '' },
    wps: { svg: 'wps.svg', name: '' },
    wsf: { svg: 'wsf.svg', name: '' },
    xhtml: { svg: 'xhtml.svg', name: '' },
    xlr: { svg: 'xlr.svg', name: '' },
    xls: { svg: 'xls.svg', name: '' },
    xlsx: { svg: 'xlsx.svg', name: '' },
    xml: { svg: 'xml.svg', name: '' },
    yuv: { svg: 'yuv.svg', name: '' },
    zip: { svg: 'zip.svg', name: 'ZIP Archive' },
    '???': { svg: 'file.svg', name: 'Unknown File Type' },
}

export function getExtInfo(filename, isDirectory) {
    //we are assuming no dots in folder names!
    const ext = isDirectory ? 'folder' : /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : '???'
    if (extensions[ext]) {
        if (extensions[ext].name.length === 0) {
            return { svg: extensions[ext].svg, name: ext.toUpperCase() + ' File' }
        }
        return { svg: extensions[ext].svg, name: extensions[ext].name }
    }
    return { svg: extensions.file.svg, name: ext.toUpperCase() + ' File' }
}

export function getSize(size) {
    if (size === 'folder') return '<Directory>'
    return prettyBytes(parseInt(size))
}

export function getDate(timestamp) {
    const time = new Date(Number(timestamp))
    return `${time.toLocaleDateString()} ${time.toLocaleTimeString('de-DE')}`
}

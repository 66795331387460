import React from 'react'
import { getExtInfo, getSize, getDate } from '../helpers/files'

export default function Listitem({ data }) {
    const { dir, url, name, size, dateModified, isDirectory } = data
    const folderUrl = dir === '/' ? dir + name : dir + '/' + name // special handle for root dir name being /
    const extInfo = getExtInfo(name, isDirectory)
    return (
        <div className="text-gray-200 font-medium bg-gray-800 odd:bg-gray-900 hover:bg-blue-600">
            <a href={url || '#' + folderUrl}>
                <div className="flex p-3">
                    <div className="flex-1 text-left inline-flex items-center">
                        <img
                            className="h-5 flex mx-2 al fileIcon"
                            src={'/.fileExplorerReact/icons/fileExtensions/' + extInfo.svg}
                            alt="i"
                        />
                        {name}
                    </div>
                    <div className="flex-1 text-left">{extInfo.name}</div>
                    <div className="flex-1 text-left">{getSize(size)}</div>
                    <div className="flex-1 text-left">{getDate(dateModified)}</div>
                </div>
            </a>
        </div>
    )
}

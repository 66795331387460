import React from 'react'

export default function Breadcrumbs({ directories }) {
    return (
        <ul className="breadcrumbs">
            <li key="rootCrumb-li">
                <a key="rootCrumb-a" href="#/" className="breadcrumb">
                    /
                </a>
            </li>

            {directories.map((directory, index) => {
                const isLast = index === directories.length - 1
                const fullDir = '/' + directories.slice(0, index + 1).join('/')
                return (
                    <li key={'bc-li-' + index}>
                        <a
                            href={'#' + fullDir}
                            className={isLast ? 'breadcrumb breadcrumb--last' : 'breadcrumb'}
                            key={'bc-a-' + index}
                        >
                            {decodeURI(directory)}
                        </a>
                    </li>
                )
            })}
        </ul>
    )
}

import React from 'react'
import Listitem from './Listitem'

export default function List({ locations }) {
    return (
        <div className="shadow-lg flex-1 flex-col flex overflow-hidden shadow-xl rounded overflow-hidden mt-0">
            <div className="flex-0 flex font-bold bg-gray-900 border-b">
                <div className="flex-1 text-gray-500 text-left px-3 py-4">Filename</div>
                <div className="flex-1 text-gray-500 text-left px-3 py-4">Type</div>
                <div className="flex-1 text-gray-500 text-left px-3 py-4">Size</div>
                <div className="flex-1 text-gray-500 text-left px-3 py-4">Date Modified</div>
            </div>
            <div className="flex-1 overflow-y-auto bg-gray-600">
                {locations.error || locations.length === 0 ? (
                    <div className="flex items-center text-gray-200 font-medium bg-gray-900 h-10">
                        <span className="align-middle mx-10">
                            looks to be empty here, use the Buttons above to navigate back{' '}
                            {locations.msg ? ' ERR: ' + locations.msg : ''}
                        </span>
                    </div>
                ) : (
                    locations.map((elem, index) => {
                        return <Listitem data={elem} key={index} />
                    })
                )}
            </div>
        </div>
    )
}

import React from 'react'

export default function Search({ setLocations, currentLocations }) {
    return (
        <div className="flex inline-block bg-gray-400 items-center">
            <label className="mx-1 align-middle" htmlFor="search">
                Search Current Folder
            </label>
            <input
                className="rounded-md border-gray-500 bg-gray-300 my-1 border-2"
                type="text"
                name="search"
                id="search"
                onChange={(e) => {
                    if (e.target.value.length === 0) {
                        setLocations(currentLocations)
                        return
                    }
                    const filtered = currentLocations.filter((location) =>
                        location.name.toLowerCase().includes(e.target.value.toLowerCase())
                    )
                    console.log(e.target)
                    setLocations(filtered)
                }}
            />
        </div>
    )
}
